import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

export default function iosguide() {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const images = data.allFile.edges
        return images.map(({ node }, index) => (
          <div className="py-3">
            <Img fixed={node.childImageSharp.fixed} />
          </div>
        ))
      }}
    />
  )
}

const query = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "iosguide" } }
      sort: { order: ASC, fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 310, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          relativePath
        }
      }
    }
  }
`
