import React from "react"
import { DiAndroid, DiApple } from "react-icons/di"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const getData = graphql`
  {
    info: site {
      siteMetadata {
        android
        ios32
        ios64
      }
    }
  }
`

export default function download() {
  return (
    <StaticQuery
      query={getData}
      render={data => {
        const siteData = data.info.siteMetadata
        const { android, ios32, ios64 } = siteData
        return (
          <div className="container">
            <div className="row pt-5 pb-2">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-2 appbox py-2 mr-2 mb-3">
                <div className="d-flex">
                  <div>
                    <DiAndroid className="icon-android pr-1" />
                  </div>
                  <div>Android</div>
                </div>
                <div className="small text-center py-2">
                  <OutboundLink
                    href={android}
                    alt="918kiss Android APK Download"
                    className="text-dark"
                  >
                    918Kiss Android
                  </OutboundLink>
                </div>
                <OutboundLink
                  href={android}
                  alt="918kiss APK Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2">
                      Android
                  </button>
                </OutboundLink>
              </div>
              <div className="col-sm-12 col-lg-2 appbox py-2 mr-2 mb-3">
                <div className="d-flex">
                  <div>
                    <DiApple className="icon-ios-64 pr-1" />
                  </div>
                  <div>IOS</div>
                </div>
                <div className="small text-center py-2">
                  <OutboundLink
                    href={ios64}
                    alt="918kiss Iphone 5s+ IOS Download"
                    className="text-dark"
                  >
                    Iphone 5s+
                  </OutboundLink>
                </div>
                <OutboundLink
                  href={ios64}
                  alt="918kiss Iphone 5s+ Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-red">
                      IOS 64Bit
                  </button>
                </OutboundLink>
              </div>
              <div className="col-sm-12 col-lg-2 appbox py-2 mr-2 mb-3">
                <div className="d-flex">
                  <div>
                    <DiApple className="icon-ios-32 pr-1" />
                  </div>
                  <div>IOS</div>
                </div>
                <div className="small text-center py-2">
                  <OutboundLink
                    href={ios32}
                    alt="918kiss Iphone 5s IOS Download"
                    className="text-dark"
                  >
                    Iphone 5 or lower
                  </OutboundLink>
                </div>
                <OutboundLink
                  href={ios32}
                  alt="918kiss Iphone 5s Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-orange">
                      IOS 32Bit
                  </button>
                </OutboundLink>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        )
      }}
    />
  )
}
