import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Download from "../components/download"
import { FaHandPointRight } from "react-icons/fa"
import Iosguide from "../components/iosguide"
import ReactGA from "react-ga"

ReactGA.pageview("/index.html")

const IndexPage = () => (
  <Layout>
    <SEO
      title="918kiss"
      keywords={[
        `918kiss apk`,
        `918kiss ios`,
        `918kiss apk free download`,
        `download 918kiss apk`,
        `918kiss apk download for android mobile`,
        `918kiss app`,
        `918kiss apk download`,
        `918kiss download android`,
        `918kiss download apk`,
        `918kiss Malaysia`,
      ]}
    />
    <Download />
    <div className="container">
      <div className="row">
        <div className="col-lg-3" />
        <div className="col-sm-12 col-md-12 col-lg-6 text-center appbox py-2">
          <div className="row">
            <div className="col-sm-12 text-left">
              <div className="d-flex">
                <div className="pr-2">
                  <FaHandPointRight className="icon-hand" />
                </div>
                <div>ios 9+ install guide</div>
              </div>
            </div>
          </div>
          <Iosguide />
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
